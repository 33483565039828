"use strict";

/* global Storage */
var formLocalStorageKey = "_go_vietnam";
var textId;
var debugMode = false;
var clearLocalStorage = false;
var validateOn = true;
var isStorageUsing = true;
var webStorage = {};
var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
var ivshour;
var translations = null;
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (fun /*, thisp*/) {
    var len = this.length;
    if (typeof fun != "function") throw new TypeError();
    var thisp = arguments[1];
    for (var i = 0; i < len; i++) {
      if (i in this) fun.call(thisp, this[i], i, this);
    }
  };
}
function getTranslationsFromLang() {
  var currLang = $('body').attr('data-current-lang');
  var ajaxParams = [];
  for (var i = 0; i < arguments.length; i++) {
    ajaxParams.push([arguments[i], currLang]);
  }
  var ajaxParamsJson = JSON.stringify(ajaxParams);
  $.ajax({
    type: 'POST',
    url: '//' + window.location.hostname + '/langTranslation/',
    data: {
      params: ajaxParamsJson
    },
    dataType: 'json',
    cache: false,
    async: false,
    success: function success(result) {
      translations = result;
    },
    error: function error(e) {
      console.log(e);
    }
  });
  return null;
}
getTranslationsFromLang('form field warning', 'form date callendar button prev', 'form date callendar button next', 'form field cyrillic warning', 'form field cyrillic warning2', 'saved', 'save your application', 'ports air', 'ports land', 'ports sea', 'Menu 3 Link 1', 'form label nonlatin error', 'invalid mimeType', 'form port of arrival', 'form port of exit');

//////////////////////////////
// show phone number on page
//////////////////////////////

showPageEmail();
getPhoneNumbers();
function showPageEmail() {
  if (translations !== null) {
    var email = $('.support-email');
    var mailName = translations['Menu 3 Link 1'];
    email.html(mailName);
    email.attr('href', 'mailto:' + mailName);
  }
}
function getPhoneNumbers() {
  $.ajax({
    type: 'POST',
    url: '//' + window.location.hostname + '/get-phone-number/',
    data: {
      language: $('body').data('current-lang')
    },
    dataType: 'json',
    cache: false,
    success: function success(result) {
      if (result.status === true) {
        showPagePhone(result.numbers);
      } else {
        $('.callus-single, .callus-dropdown, .phone-numbers-list').remove();
      }
    },
    error: function error(e) {
      console.log(e);
      var httpLog = new XMLHttpRequest();
      var params = "message=" + window.location.href + " main.js:getPhoneNumbers()\n\n Phone numbers access has been failed";
      httpLog.open("POST", "/logger.php", true);
      httpLog.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      httpLog.send(params);
    }
  });
}
function showPagePhone(phoneNumbers) {
  if (typeof phoneNumbers[0] !== "undefined") {
    var primaryPhone = phoneNumbers[0],
      headerPhoneAdditional = $('.navbar-phone-link-additional'),
      callusIcon = $('.callus-icon');
    addPhoneToHeaderElements($('.navbar-phone-link-primary'), primaryPhone);
    addPhoneToCallusElements($('.callus-primary'), primaryPhone);
    addPhoneToRushInformation($('.noticeTable .number'), primaryPhone);
    addPhoneToRushInformationOnHomepage($('.rush-number'), primaryPhone);
    if (typeof phoneNumbers[1] !== "undefined") {
      var additionalPhone = phoneNumbers[1];
      addPhoneToHeaderElements(headerPhoneAdditional, additionalPhone);
      addPhoneToCallusElements($('.callus-additional'), additionalPhone);
      $('.phone-number.callus-single').remove();
      $('.phone-number.callus-dropdown').removeClass('hidden');
    } else {
      callusIcon.attr('href', 'tel:' + primaryPhone.raw_number);
      $('.phone-number.callus-dropdown').remove();
      headerPhoneAdditional.remove();
    }
  }
}
function addPhoneToHeaderElements(header, data) {
  header.find(".area-code").text(data.number.trim().substr(0, 3));
  header.find(".number").text(data.number.trim().substr(3));
  header.find(".language").text('(' + data.title + ')');
}
function addPhoneToCallusElements(callus, data) {
  callus.attr('href', 'tel:' + data.raw_number);
  callus.find('.img-lang').attr('src', '/assets/img/lang/' + data.code.toLowerCase() + '.svg');
}
function addPhoneToRushInformation(callus, data) {
  callus.attr('href', 'tel:' + data.raw_number).find('b').text(data.number.trim());
}
function addPhoneToRushInformationOnHomepage(callus, data) {
  callus.attr('href', 'tel:' + data.raw_number).text(data.number.trim());
}
$(function () {
  deferImages();
  dropdownMenus();

  // Remove old storage
  if (typeof Storage !== "undefined") {
    var lastDate = localStorage.getItem(formLocalStorageKey + '_date');
    if (lastDate !== null && parseInt(lastDate) + 5 * 24 * 60 * 60 * 1000 < Date.now()) {
      localStorage.removeItem(formLocalStorageKey);
      localStorage.removeItem(formLocalStorageKey + '_date');
    }
  }
});
function dropdownMenus() {
  var languages = $('#dropdown-language');
  var phones = $('#dropdown-phone-list');
  $('#dropdown03').on('click', function () {
    languages.toggle();
  });
  $('#dropdown-phone-button').on('click', function () {
    phones.toggle();
  });
  $(document).click(function (event) {
    if (!$(event.target).closest('#dropdown03').length) {
      languages.hide();
    }
    if (!$(event.target).closest('#dropdown-phone-button').length) {
      phones.hide();
    }
  });
}

// for SEO
// add class "defer-img" and data-src="src" to defer image, src set to "defer.png"
function deferImages() {
  var imgDefer = $('img.defer-img');
  if (imgDefer.length > 0) {
    var src = '';
    imgDefer.each(function () {
      src = $(this).attr('data-src');
      $(this).attr('src', src);
    });
  }
}